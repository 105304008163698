@charset 'UTF-8';

* {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

html,
body,
div,
span,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
em,
img,
small,
strong,
sub,
sup,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
footer,
header,
menu,
nav,
section,
video,
a {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
li {
  line-height: 1;
}

img {
  max-width: 100%;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  cursor: pointer;
}

button:focus {
  outline: none;
}

a {
  color: inherit;
  text-decoration: none;
}

textarea {
  resize: none;
}