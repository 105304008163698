@charset 'UTF-8';

@import '../../assets/css/variables';


.font_style-list {
    li {
        position: relative;
        width: 100%;
        padding: 20px 80px 20px 10px;
        border-bottom: 1px solid #ddd;
        text-align: left;

        &:last-child {
            border-bottom: none;
        }
    }
}

.weight {
    &_100 {
        font-weight: 100;
    }

    &_200 {
        font-weight: 200;
    }
    
    &_300 {
        font-weight: 300;
    }

    &_400 {
        font-weight: 400;
    }

    &_500 {
        font-weight: 500;
    }

    &_600 {
        font-weight: 600;
    }

    &_700 {
        font-weight: 700;
    }

    &_800 {
        font-weight: 800;
    }

    &_900 {
        font-weight: 900;
    }
}

.font_style {
    &-category {
        font-size: 14px;
    }

    &-preview {
        min-height: 45px;
        line-height: 1.5;
        margin-top: 15px;
        font-size: 30px;
    }

    &_select-btn {
        position: absolute;
        top: 40px;
        right: 0;
        padding: 8px;
        border: none;
        background-color: transparent;
        color: #0f4c81;
        font-size: 14px;
        transition: background-color 0.3s, color 0.3s;

        &:hover {
            background-color: #0f4c81;
            color: #fff;
        }
    }
}

.font_embed {
    &-list {
        li {
            line-height: 1.5;
            margin-top: 30px;
            text-align: left;
            word-break: break-all;
        }
    }

    &-title {
        color: $main;
        margin-bottom: 15px;
        font-size: 20px;
    }

    &-link {
        padding: 20px;
        color: #333;
        background-color: #f5f5f5;
        border-radius: 20px;
        font-size: 16px;
        font-weight: 100;
    }
}

.dashed-wrap {
    &.dashed-wrap--embed {
        padding: 60px 50px;
    }
}

.font-search {
    .input-wrap {
        .input-text {
            width: 80%;
            margin-left: 20px;
        }
    }
}

.licence_link {
    display: inline-block;
    margin-top: 20px;
    color: $main;
    text-decoration: underline
}