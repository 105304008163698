@charset 'UTF-8';

/* ===========================================================================
    import webfont
============================================================================= */

// Noto Sans KR
@import url('//cdn.jsdelivr.net/gh/snz-hayden/toolo/fonts/notosans_kr/notosans_kr_ver2.css');

// Typography
$font-family-sans-serif: 'S-CoreDream', sans-serif;
$font-malgun: 'malgun gothic', '맑은 고딕', MalgunGothic, sans-serif;
$font-nanum-gothic: 'Nanum Gothic', sans-serif;
$font-nanum-square: 'NanumSquare', sans-serif;
$font-size-base: 16px;
$line-height-base: 1.3;

/* font */
html, input, button, textarea {
	font-family: $font-family-sans-serif, sans-serif;
	font-size: 16px;
}

// s core dream
@font-face {
	font-family: 'S-CoreDream';
	src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-3Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'S-CoreDream';
	src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-4Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'S-CoreDream';
	src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-5Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'S-CoreDream';
	src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-6Bold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

