@charset 'UTF-8';

$main : #1da1f2;

/*************************************
    scroll bar
*************************************/
::selection {
  color: #fff;
  background-color: $main;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  height: 50px;
  width: 50px;
  background: $main;
  border-radius: 8px;
}

/*************************************
    common class
*************************************/
.sz-wrap {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
}

.blind {
  visibility: hidden;
  overflow: hidden;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0
}

.clearfix::after {
  content: '';
  display: block;
  clear: both;
}

/*************************************
    navigation
*************************************/
#navigation {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.nav-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.nav-container {
  position: fixed;
  top: 0;
  left: -500px;
  width: 500px;
  height: 100%;
  background-color: #fff;
  z-index: 1;
  transition: left 1.2s 0.5s cubic-bezier(0.215, 0.610, 0.355, 1);
}

.nav-header {
  position: relative;
  width: 100%;
  padding: 33px 0;
  background-color: $main;
}

#navSvg {
  position: absolute;
  top: 7px;
  left: 66px;

  svg {
    width: 80px;
    height: 80px;
  }

  path {
    stroke: #fff;
  }
}

#nav {
  padding: 80px;
}

#navigation.active {
  .nav-container {
    left: 0;
  }
}

.tool-category {
  margin-bottom: 36px;
}

.category-name {
  padding-bottom: 10px;
  margin-bottom: 20px;
  color: #acacac;
  border-bottom: 1px solid #acacac;
  font-size: 16px;
  font-weight: 300;
}

.tool-list {
  li {
    margin-bottom: 20px;
    color: #333;
    font-size: 20px;
    font-weight: 300;
    text-indent: 20px;

    a {
      color: inherit;

      &:hover {
        color: $main;
        font-weight: 500;
      }

      &:hover::before {
        content: '';
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 7px;
        background: url(../images/common/deco-icon.png) center center / cover no-repeat;
        vertical-align: 0px;
        animation: rotate 3s linear infinite both;
      }
    }

    .wait {
      color: #acacac;
      font-size: 14px;
    }
  }
}

/*************************************
    버튼
*************************************/
.default-btn {
  padding: 7px 25px;
  color: #333;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid $main;
  border-radius: 20px;
  letter-spacing: -0.64px;
  vertical-align: middle;
  font-family: 'S-CoreDream';
  transition: background-color 0.3s;

  &:focus,
  &:hover {
    color: #fff;
    background-color: $main;
  }
}

/*************************************
    common layout
*************************************/
#container {
  min-height: calc(100vh - 175px);
}

.content-wrap {
  padding-top: 40px;
  padding-bottom: 100px;
}

.top-title {
  padding: 70px 0;
  color: #fff;
  background-color: #1da1f2;
  text-align: center;

  h2 {
    margin-bottom: 7px;
    font-size: 32px;
    font-weight: 500;
  }

  p {
    line-height: 1.3;
    font-size: 20px;
    font-weight: 300;
  }

  &.design {
    background-color: #ff2a38;
  }

  &.font {
    background-color: #0f4c81;
  }
}

.gray-wrap {
  padding: 40px;
  background-color: #f1f1f1;
  border-radius: 20px;
  text-align: center;

  &.top {
    margin-bottom: 40px;
  }

  .gray-title {
    color: #333;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 40px;
  }
}

.sub-title {
  margin-bottom: 28px;
  color: #333;
  font-size: 20px;
  font-weight: 500;
  ;
}

.sub-text {
  margin-top: 30px;
  line-height: 1.5;
  color: #333;
  font-size: 16px;
  font-weight: 300;
}

.input-wrap {
  padding: 22px 40px;
  background-color: #d2ecfc;
  border-radius: 40px;

  label {
    display: inline-block;
    margin-right: 15px;
    color: #333;
    font-size: 20px;
    font-weight: 500;
    vertical-align: middle;
  }

  .input-box {
    display: inline-block;
    position: relative;
  }

  input {
    width: 248px;
    height: 36px;
    padding: 0 45px 0 30px;
    color: #333;
    border: 1px solid transparent;
    border-radius: 18px;

    &:focus {
      border: 1px solid $main;
      outline: none;
    }
  }

  .input-text {
    width: 100%;
    height: 40px;
  }

  .input-tail {
    position: absolute;
    top: 4px;
    right: 18px;
    color: #999;
    font-size: 16px;
  }
}

.input-wrap.full {
  width: 100%;
  
  .input-box {
    width: 100%;
  }
}

.input-wrap.white {
  display: inline-block;
  background-color: #fff;

  label {
    font-size: 18px;
    font-weight: 400;
  }

  input {
    border: 1px solid #acacac;

    &:focus {
      border: 1px solid $main;
      outline: none;
    }
  }

  .default-btn {
    margin-left: 15px;
  }
}

.flex-box {
  display: flex;
  margin-left: -20px;

  .flex-item {
    flex: 1;
    align-self: stretch;
    padding-left: 20px;
  }
}

.right_sidebar {
  margin-bottom: 40px;

  .flex-item {
    &:first-child {
      flex-basis: 70%;
    }
  }
  
  .flex-item {
    &:last-child {
      flex-basis: 30%;
    }
  }
}

.dashed-wrap {
  height: 100%;
  padding: 60px;
  border: 2px dashed #acacac;
  border-radius: 60px;
  text-align: center;

  &.left {
    text-align: left;
  }
}

.content-title {
  margin-bottom: 30px;
  color: #333;
  font-size: 30px;
  font-weight: 500;
  letter-spacing: -1.2px;
}

.deco-text {
  &::before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 7px;
    background: url(../images/common/deco-icon.png) center center / cover no-repeat;
    vertical-align: -2px;
  }
}

.info-wrap {
  margin-top: 40px;
}

.info-title {
  margin-bottom: 17px;
  color: $main;
  font-size: 20px;
  font-weight: 500;
}

.info-list {
  display: inline-block;

  li {
    line-height: 1.5;
    color: #333;
    font-size: 16px;
    font-weight: 300;
    text-align: left;
  }
}

.result-wrap {
  width: 100%;
  max-width: 1180px;
  padding: 20px 10px;
  margin: 0 auto 40px;
  color: #333;
  background-color: #d2ecfc;
  border-radius: 40px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;

  .result {
    display: inline-block;
    margin-left: 25px;
    font-size: 30px;
    vertical-align: -3px;
  }
}

form {
  .default-btn {
    display: block;
    width: 100%;
    max-width: 1180px;
    margin: 40px auto;
  }
}

.link-list {
  margin-left: -20px;

  &::after {
    content: '';
    display: block;
    clear: both;
  }

  li {
    float: left;
    width: 33.33%;
    height: 300px;
    padding-left: 20px;
    margin-top: 30px;
    
    .dashed-wrap {
      padding: 30px 40px;
      transition: box-shadow 0.3s;
    }

    .content-title {
      padding-bottom: 25px;
      margin-bottom: 15px;
      border-bottom: 1px solid #acacac;
      text-align: left;
      
      &::after {
        content: '';
        display: block;
        clear: both;
      }
    }

    .font-title {
      color: #1da1f2;
      font-size: 24px;
      font-weight: 500;
    }

    .font-company {
      display: block;
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 400;
    }
    
    .font-text {
      line-height: 1.5;
      height: 120px;
      font-size: 40px;
      text-align: left;
      word-break: break-all;
      overflow: auto;
    }

    &:hover {
      .dashed-wrap {
        box-shadow: 6.3px 7.8px 10px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }
}